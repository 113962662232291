<template>
  <v-container fluid class="pa-0">
    <v-overlay v-if="loading" color="white" class="align-center justify-center" z-index="100000">
      <v-img src="https://vblimages.blob.core.windows.net/images/6fcdddd2-bbf3-483a-9b86-30017d8d26ed.png" max-height="240px" max-width="240px">
        <div class="align-center justify-center d-flex fill-height" style="width: 100%">
          <v-progress-circular indeterminate size="200" color="#094886" />
        </div>
      </v-img>
    </v-overlay>
    <v-row dense class="not-printable">
      <v-col cols="12" class="pl-4">
        <vbl-breadcrumbs :crumbs="breadcrumbs"></vbl-breadcrumbs>
      </v-col>
      <v-col cols="12">
        <v-window  v-if="store" v-model="window" touchless>
          <v-window-item :key="0">
            <StoreFront :store="store"></StoreFront>
          </v-window-item>
          <v-window-item :key="1">
            <ProductDisplay
              v-if="window === 1"
              :tag="tag"
              :productIn="selectedProduct"
            ></ProductDisplay>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
    <v-btn v-if="false" color="success" @click="onDebug">debug store</v-btn>
  </v-container>
</template>

<script>
import Product from '@/classes/Product'
const StoreFront = () => import('@/components/Store/StoreFront.vue')
const ProductDisplay = () => import('@/components/Store/ProductDisplay.vue')

export default {
  props: ['tag'],
  data () {
    return {
      loading: false,
      window: 0,
      store: null,
      selectedProduct: null
    }
  },
  computed: {
    breadcrumbs () {
      if (!this.selectedProduct) return []
      const crumbs = [
        {
          text: this.store.name,
          to: { name: 'store-front' },
          exact: true,
          disabled: false
        },
        {
          text: this.selectedProduct?.title,
          disabled: true
        }
      ]
      return crumbs
    }
  },
  methods: {
    asyncData () {
      this.loading = true
      this.$VBL.store.get(this.subdomain)
        .then(r => {
          this.store = r.data
          this.checkSelected()
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    checkSelected () {
      if (this.tag && this.store && this.store.products.length) {
        const p = this.store.products.find(f => f.urlTag === this.tag)
        this.selectedProduct = new Product(p)
        this.window = 1
      } else {
        this.window = 0
        this.selectedProduct = null
        this.search = null
      }
    },
    onDebug () {
      this.window = 1
    }
  },
  mounted () {
    this.asyncData()
  },
  watch: {
    tag: 'checkSelected'
  },
  components: {
    StoreFront,
    ProductDisplay
  }
}
</script>
