import max from 'lodash.max'
import min from 'lodash.min'
import sum from 'lodash.sum'
import flatten from '../helpers/ArrayFlatten'
import { firstBy } from 'thenby'

export default class Product {
  id = 0
  organizationId = null
  parentId = null
  name = null
  title = null
  subtitle = null
  description = null
  descriptionIsHtml = true
  price = null
  oldPrice = null
  deleted = false
  published = false
  moreInfo = null
  moreInfoBtn = null
  moreInfoOpen = false
  urlTag = null
  jsonProps = {
    imageLibrary: {
      images: []
    },
    requirements: {
      player: false
    }
  }

  _props = []
  showInStore = true
  inventory = 99999
  options = []
  player = null
  selected = -1
  altAmount = null
  inventoryHistory = []
  myOrder = 0

  constructor (dto) {
    if (dto) this.update(dto)
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    Object.assign(this, dto)

    if (dto.jsonProps) {
      const isString = typeof dto.jsonProps === 'string'
      this.jsonProps = isString ? JSON.parse(dto.jsonProps) : dto.jsonProps
    }

    if (dto.options && dto.options.length > 0) {
      this.options = dto.options.map(o => new Product(o))
    }
  }

  correctOrder () {
    if (this.options.length === 0) return
    this.options = this.options.sort(firstBy('myOrder'))
    this.options.forEach((f, i) => {
      f.myOrder = i + 1
      f.correctOrder()
    })
  }

  addOption (dto) {
    const o = new Product(dto)
    o.myOrder = this.options.length + 1
    this.options.push(o)
    return o
  }

  get dto () {
    if (this.keepInventory) {
      this.finalOptions.forEach(f => f.calcInventoryChange())
    }
    return {
      id: this.id,
      organizationId: this.organizationId,
      parentId: this.parentId,
      name: this.name,
      title: this.title,
      subtitle: this.subtitle,
      description: this.description,
      descriptionIsHtml: this.descriptionIsHtml,
      price: +this.price,
      oldPrice: this.oldPrice,
      deleted: this.deleted,
      published: this.published,
      moreInfo: this.moreInfo,
      moreInfoBtn: this.moreInfoBtn,
      moreInfoOpen: this.moreInfoOpen,
      urlTag: this.urlTag,
      jsonProps: JSON.stringify(this.jsonProps),
      props: this.props,
      showInStore: this.showInStore,
      options: this.options.map(m => m.dto),
      inventory: this.inventory,
      inventoryHistory: this.inventoryHistory,
      myOrder: this.myOrder
    }
  }

  get purchaseDto () {
    return {
      id: this.id,
      player: this.player ? this.player.dto : null,
      urlTag: this.urlTag,
      price: this.price
    }
  }

  get status () {
    return this.published ? 'Active' : 'Inactive'
  }

  get selectedOption () {
    return this.selected >= 0 ? this.options[this.selected] : null
  }

  get salesRecords () {
    const a = [...this.inventoryHistory.filter(f => f.note.toLowerCase() === 'sale')]
    this.options.forEach(opt => {
      a.push(...opt.salesRecords)
    })

    return a
  }

  get comingSoon () {
    return this.allPricesUnfiltered.findIndex(f => f <= 0) > -1
  }

  get allPricesUnfiltered () {
    const a = []
    this.price && a.push(+this.price)
    this.options.forEach(opt => {
      a.push(...opt.allPrices)
    })
    return a
  }

  get allPrices () {
    const a = []
    this.price && a.push(+this.price)
    this.options.forEach(opt => {
      a.push(...opt.allPrices)
    })
    return a.filter(f => f > 0)
  }

  get priceRange () {
    const high = max(this.allPrices)
    const low = min(this.allPrices)

    return [low, high]
  }

  get hasRange () {
    const x = new Set(this.priceRange)
    return x.size > 1
  }

  get keepInventory () {
    return this.props.includes('inventory')
  }

  set keepInventory (v) {
    this.props = this.props.filter(f => f !== 'inventory')
    if (v) {
      this.props.push('inventory')
    }
    this.options.forEach(o => { o.keepInventory = v })
  }

  get inventoryCount () {
    return this.options.length ? sum(this.options.map(m => m.inventoryCount)) : this.inventory
  }

  get inventoryLogSum () {
    const i = this.inventoryHistory.filter(f => f.id > 0)
    return i.length > 0 ? sum(i.map(m => m.amount)) : 0
  }

  calcInventoryChange () {
    this.inventoryHistory = this.inventoryHistory.filter(f => f.id > 0)
    const note = this.inventoryHistory.length === 0 ? 'Starting Inventory' : 'Inventory Adjustment'
    const x = this.inventory - this.inventoryLogSum
    if (x !== 0) {
      this.inventoryHistory.push({
        id: 0,
        organizationProductId: this.id,
        amount: x,
        note: note,
        status: 'In Browser'
      })
    }
  }

  get finalOptions () {
    return flatten(this.options.length ? this.options.map(o => o.finalOptions) : [this])
  }

  get limit () {
    const l = this.props.find(f => f.startsWith('limit-'))
    if (l) {
      return +l.substring(6)
    }
    return 99
  }

  set limit (v) {
    this.props = this.props.filter(f => !f.startsWith('limit-'))
    if (v) {
      this.props.push(`limit-${v}`)
    }
    this.options.forEach(o => { o.limit = v })
  }

  get hasLimit () {
    return this.props.find(f => f.startsWith('limit-'))
  }

  get iLimit () {
    if (this.keepInventory) {
      return min([this.inventoryCount, this.limit])
    }
    return this.limit
  }

  get displayPrice () {
    return this.price === -13 ? this.altAmount : this.price
  }

  get pickupOnly () {
    return !!this.props.find(f => f.startsWith('pickupOnly'))
  }

  get shippingOnly () {
    return !!this.props.find(f => f.startsWith('shippingOnly'))
  }

  get donation () {
    return !!this.props.find(f => f.startsWith('donation'))
  }

  get contactReq () {
    const l = this.props.find(f => f.startsWith('require-'))
    if (l) {
      return l.replace('require-', '')
    }
    return 'contact'
  }

  set contactReq (v) {
    this.props = this.props.filter(f => !f.startsWith('require-'))
    if (v) {
      this.props.push(`require-${v}`)
    }
    this.options.forEach(o => { o.contactReq = v })
  }

  get delivery () {
    const l = this.props.find(f => f.startsWith('delivery-'))
    if (l) {
      return l.replace('delivery-', '')
    }
    return 'pickup'
  }

  set delivery (v) {
    this.props = this.props.filter(f => !f.startsWith('delivery-'))
    if (v) {
      this.props.push(`delivery-${v}`)
    }
    this.options.forEach(o => { o.delivery = v })
  }

  get props () {
    return this._props
  }

  set props (v) {
    this._props = v
    this.options.forEach(o => { o.props = [...v] })
  }

  get imageLibrary () {
    return this.jsonProps.imageLibrary
  }

  get displayImages () {
    return this.jsonProps.imageLibrary.images
  }
}
